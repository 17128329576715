<template lang="html">
    <div id="topMenu" :class="sticking ? '' : ''" :show="show" class="w-100 font-3">
        <div class="rel w-100 h-100 position-relative d-flex flex-md-wrap justify-content-between align-items-center align-content-center py-1 ps-3 pe-4 px-lg-0">

        <div class="icon ms-md-4 ms-2 d-flex align-items-center align-content-center justify-content-start">
            <div class="perfectSquare" @click="goToHome()" role="button">
                <img :src="logo" class="logo">
            </div>
        </div>

        <div class="staticRight col-9 justify-content-end d-lg-none d-flex flex-nowrap align-items-center h-75 py-1">
            <div id="carIndicator" class="carIndicator min carSpace position-relative d-flex justify-content-end align-items-center align-content-center" role="button" @click="goToCar()">
                <span class="font-5 carQ">{{carItems}}</span>
                <img :src="a43" alt="">
            </div>
            <div class="divisor mobile me-2 ms-2"></div>
            <div class="menuIcon"  @click="toggleMenu">
                <img v-svg-inline :src="a21">
            </div>
        </div>

        <div class="staticRight desktop flex-fill d-none d-lg-flex ms-md-4 justify-content-between">
            <div class="left-section d-flex"> <!-- Contenedor de elementos a la izquierda -->
                <div @click="goToMenu()" class="buttonText d-flex px-4 font-3 h-100 align-items-center align-content-center" role="button">Menu</div>
                <div class="divisor" v-if="userLevel > 0"></div>
                <div @click="goToAccount()" v-if="userLevel > 0" class="buttonText d-flex px-4 font-3 h-100 align-items-center align-content-center" role="button">Cuenta</div>
            </div>
        
            <div class="d-flex ms-auto align-items-center align-content-center pe-4 position-relative"> <!-- Contenedor de elementos a la derecha -->
                <button :class="userLevel == 0 ? 'btn-2' : 'btn-1 font-4'" class="me-4 contextMenuActivator position-relative" ref="contextMenuActivator" @click="openContext()" v-if="this.$route.name != 'account' || userLevel == 2"><div class="labeled">{{textUser}}</div><span class="icon"><img :src="a42"></span></button>
                <div class="contextMenu" v-if="contextMenuStatus" ref="contextMenu">
                    <div class="position-relative w-100 h-100">
                        <span class="triangulePoint"></span>
                        <div class="menuContainer w-100 h-100 d-flex flex-wrap font-3 align-items-start align-content-start">
                            <div class="menuBotton w-100 py-3 px-3 text-start" @click="goToAccount()">{{phrases.f1[lang]}}</div>
                            <div class="menuBotton w-100 py-3 px-3 text-start" @click="goToOrders()">{{phrases.f2[lang]}}</div>
                            <div class="menuBotton w-100 py-3 px-3 text-start" @click="closeSession()">{{phrases.f3[lang]}}</div>
                        </div>
                    </div>
                </div>
                <div class="divisor me-2"></div>
                <div id="carIndicator" class="carIndicator carSpace position-relative d-flex justify-content-center align-items-center align-content-center" role="button" @click="goToCar()">
                    <span class="font-5 carQ">{{carItems}}</span>
                    <img :src="a43" alt="">
                </div>
            </div>
        </div>

        <div v-if="showMenu" class="floatingMenu" @click="closeMobile($event)">
            <!-- Contenido del menú desplazable -->
            <div class="menuContent d-flex flex-wrap align-items-start align-content-start justify-content-center font-3" :class="menuStatus">

                <div class="w-100 align-items-center align-content-center justify-content-center py-5">
                    <img :src="logo" class="logo pe-none">
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center align-items-start align-content-start px-4 font-3 mb-4">
                    <div class="menuLine w-100 d-flex justify-content-start text-start align-items-start align-content-start py-2" @click="goMobile($event, 'menu')">
                        {{ phrases.menu[lang] }}
                    </div>
                    <div class="menuLine w-100 d-flex justify-content-start text-start align-items-start align-content-start py-2" @click="goMobile($event, 'account')">
                        {{ phrases.f4[lang] }}
                    </div>
                    <div class="menuLine w-100 d-flex justify-content-start text-start align-items-start align-content-start py-2" @click="goMobile($event, 'listOrders')">
                        {{ phrases.f6[lang] }}
                    </div>
                    <!--<div class="menuLine w-100 d-flex justify-content-start text-start align-items-start align-content-start py-2" @click="goMobile($event, 'store')">
                        {{ phrases.f5[lang] }}
                    </div>-->
                </div>
                <div class="w-100 d-flex align-items-center align-content-center justify-content-between py-4 px-4 mt-5">
                    <div class="col-6 d-flex justify-content-start">
                        <button @click="goToAdresses()" :class="userLevel == 0 ? 'btn-2 py-3 justify-content-center' : 'btn-1 font-4 fw-bold justify-content-start'" class="contextMenuActivator position-relative d-flex text-start align-items-center align-content-center ps-0" ref="contextMenuActivator" v-if="this.$route.name != 'account' || userLevel == 2"><div class="me-2" v-if="userLevel != 0"><img :src="a42" ></div><span class="labeled">{{textUser}}</span></button>
                    </div>

                    <div class="col-6 d-flex justify-content-end">
                        <div id="carIndicator" class="carIndicator carSpace position-relative d-flex justify-content-end align-items-center align-content-center" role="button" @click="goToCar()">
                            <span class="font-5 carQ">{{carItems}}</span>
                            <img :src="a43" alt=""></div>
                    </div> 
                </div>
            </div>
        </div>

    </div>
        

    </div>
</template>
<script>
export default {
    name:"topMenu",
    emits:['event'],
    props:{
        user:{
            default:{
                name:'',
                lastName:'',
            },
            type: Object
        },
        lang:{
            default:"es",
            type: String
        },
        car:{
            default:{},
            type:Object
        }
    },
    computed:{
        carItems(){
            let total = 0;
            for(let i in this.car.articles){
                for(let j in this.car.articles[i]){
                    total += this.car.articles[i][j].quantity;
                }
            }
            return total
        }
    },
    mounted(){
        document.addEventListener("scroll", this.scrollCalc);
        document.addEventListener('click', this.handleClick);
        this.getUserLevels();
    },
    beforeDestroy() {
		document.removeEventListener('click', this.handleClick);
	},
    beforeUnmount(){
        document.removeEventListener('click', this.handleClick);
        document.removeEventListener("scroll", this.scrollCalc);
    },
    data(){
        return {
            userLevel:0,
            textUser:'Login',
            contextMenuStatus:false,
            checking:false,
            nowScrollVal:0,
            lastScrolValue:0,
            show:true,
            sticking:false,
            a21:require('@/assets/images/a21.svg'),
            a43:require('@/assets/images/a43.svg'),
            a42:require('@/assets/images/a42.svg'),
            logo:require('@/assets/images/franchise/logo.svg'),
            phrases:{
                title:{
                    es:"ORDENA EN LÍNEA",
                    en:"ORDER ONLINE"
                },
                account:{
                    es:"CUENTA",
                    en:"ACCOUNT"
                },
                menu:{
                    es:"Menú",
                    en:"Menu"
                },
                f1:{
                    es:"Mi cuenta",
                    en:"My account"
                },
                f2:{
                    es:"Mis pedidos",
                    en:"My orders"
                },
                f3:{
                    es:"Cerrar sesión",
                    en:"close session"
                },
                f4:{
                    es:"Cuenta",
                    en:"Account"
                },
                f5:{
                    es:"Ubicaciones",
                    en:"Locations"
                },
                f6:{
                    es:"Pedidos",
                    en:"Orders"
                },
            },
            showMenu: false,
            menuStatus:'',
        }
    },
    watch:{
        show(){
            this.show === true ? (window.dispatchEvent(new CustomEvent('toggleMenuTop', {detail:true}))) : (window.dispatchEvent(new CustomEvent('toggleMenuTop', {detail:false})));
        },
        user(){
            this.getUserLevels();
        }
    },
    methods:{
        handleClick(event) {
            if(this.$refs.contextMenu && this.$refs.contextMenuActivator){
                if (!this.$refs.contextMenu.contains(event.target) && !this.$refs.contextMenuActivator.contains(event.target)) {
                    this.contextMenuStatus = false;
                }
            }
        },
        closeSession(){
            this.contextMenuStatus = false;
            this.$emit('event', {type:'closeSession'});
        },
        goMobile(e, destiny=''){
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            this.closeMobile(e);
            switch(destiny){
                case 'account':
                    this.goToAccount();
                    break;
                case 'menu':
                    this.goToMenu();
                    break;
                case 'listOrders':
                    this.goToOrders();
                    break;
                case 'addreses':
                    this.goToAdresses();
                    break;
                case 'stores':
                    this.goToStores();
                    break;
                default:
                    break;
            }
        },

        toggleMenu() {
            this.showMenu = !this.showMenu;
            this.menuStatus = this.showMenu ? 'show' : 'hidden';
        },

        formatNames(user={name:"",lastname:""}){
            let stringN = "";
            let parseHelper = "";

            if (user.hasOwnProperty('name')) {
                if(user.name.length > 0){
                    stringN += user.name.split(" ").map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()).join(" ")
                }
            }

            if (user.hasOwnProperty('lastname')) {
                if(user.lastname.length > 0){
                    stringN += " "+user.lastname.split(" ").map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()).join(" ")
                }
            }

            if (user.hasOwnProperty('lastName')) {
                if(user.lastName.length > 0){
                    stringN += " "+user.lastName.split(" ").map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()).join(" ")
                }
            }

            return stringN;
        },
        openContext(){
            if(this.user.dataToken != null){
                if(this.user.dataToken.data.level >= 1){
                    this.contextMenuStatus = !this.contextMenuStatus;
                }else{
                    this.contextMenuStatus = false;
                    this.goToAccount();
                }
            }else{
                this.contextMenuStatus = false;
                this.goToAccount();
            }
        },
        getUserLevels(){
            if(this.user.dataToken != null){
                if(this.user.dataToken.data.level == 1){
                    if(Object.keys(this.user.data.homes).length > 0){
                        if(this.user.data.homes.hasOwnProperty(this.user.data.deliveryDir)){
                            this.textUser = this.user.data.homes[this.user.data.deliveryDir].colony;
                        }else{
                            this.textUser = this.user.data.homes[Object.keys(this.user.data.homes)[0]].colony;
                        }
                    }else{
                        this.textUser = this.user.data.phone1;
                    }
                    this.userLevel = 1;
                }
                else if(this.user.dataToken.data.level == 2){
                    this.textUser = (this.user && this.user.data) ? this.formatNames(this.user.data) : '';
                    this.userLevel = 2; 
                }else{
                    this.textUser = 'Login';
                    this.userLevel = 0;
                }
            }else{
                this.textUser = 'Login';
                this.userLevel = 0;
            }
        },
        scrollCalc(){
            if(!this.checking){
                
                this.checking = true;
                this.nowScrollVal = document.documentElement.scrollTop;
                this.show = true;
                /*if(this.$route.name != 'home' && this.$route.name != 'menuGeneral' && this.$route.name != 'category'){
                    this.show = true;
                }else{
                    this.show = this.lastScrolValue < this.nowScrollVal && this.nowScrollVal >= 118 ? (this.showMenu ? true : false) : true;
                }*/
                this.sticking = document.documentElement.scrollTop >= 150 ? true : false;
                this.lastScrolValue = this.nowScrollVal;
                setTimeout(() => {
                    this.checking = false;
                }, 100);
            }
        },
        goToHome(){
            this.contextMenuStatus = false;
            if(this.$route.name != 'Home'){
                this.$router.replace({ path: '/' })
            }else{
                this.$scrollToTop();
            }
            return false
        },
        goToAccount(){
            this.contextMenuStatus = false;
            if(this.$route.name != 'account'){
                this.$router.push({ path: '/account' })
            }else{
                this.$scrollToTop();
            }
            return false
        },
        goToCar(){
            this.contextMenuStatus = false;
            if(this.$route.name != 'order'){
                this.$router.push({ path: '/order' })
            }else{
                this.$scrollToTop();
            }
            return false
        },
        goToMenu(){
            this.contextMenuStatus = false;
            if(this.$route.name != 'MenuGeneral'){
                this.$router.push({ name: 'MenuGeneral' })
            }else{
                this.$scrollToTop();
            }
            return false
        },
        goToOrders(){
            this.contextMenuStatus = false;
            if(this.$route.name != 'track'){
                this.$router.push({ path: '/track' })
            }else{
                this.$scrollToTop();
            }
            return false
        },
        goToAdresses(){
            this.contextMenuStatus = false;
            if(this.$route.name != 'account'){
                this.$router.push({ path: '/account', query: { direction: true }, })
            }else{
                this.$scrollToTop();
            }
            return false
        },
        goToStores(){
            this.contextMenuStatus = false;
            window.open('https://bostons.com.mx/location', '_blank');
        },
        closeMobile(e){
            e.stopPropagation();
            e.preventDefault();
            this.contextMenuStatus = false;
            this.menuStatus = 'hidden';
            setTimeout(()=>{
                this.showMenu = false;
            }, 300)
            
        }
    }
}
</script>
<style lang="css" scoped>
    #topMenu{
        position: sticky;
        position: -webkit-sticky;
        top:2px;
        left: 0;
        height: 118px;
        margin: 5px 5px 5px 5px;
        z-index: 1000;
        -webkit-transition: 0.05s ease;
        -moz-transition: 0.05s ease;
        -o-transition: 0.05s ease;
        -ms-transition: 0.05s ease;
        transition: 0.05s ease;
        z-index: 2000;
    }

    #topMenu::before{
        content:"";
        position: absolute;
        left:-5px;
        top:-5px;
        z-index: 1001;
        width: calc(100% + 5px);
        height:calc(100% + 5px);
        background-color:#fff;
    }

    .rel{
        position: absolute;
        left:0;
        top:0;
        z-index: 1002;
        background-color: var(--quinary);
        border-radius: 30px 30px 0 0;
    }

    body.modalOpen #topMenu{
        opacity: 0 !important;
    }

    #topMenu[show=false]{
        opacity: 0 !important;
        pointer-events: none;
    }
    body:not(.modalOpen) #topMenu[show=true]{
        opacity: 1 !important;
    }


    #topMenu.sticking{
        border-radius: 0 0 0 0 !important;
        margin: 0 0 0 0 !important;
    }

    .staticRight.desktop{
        height: 80%;
    }


    .buttonText{
        font-size: 30px;
        color:var(--tertiary)
    }

    .buttonText:hover{
        color:var(--terdecagenary)
    }

    .icon{
        width: 80px;
        height: 80px
    }
    .carIndicator{
        width: 80px;
        flex-shrink: 0;
    }
    .carIndicator .carQ{
        position: absolute;
        left: 0;
        bottom: -10px;
        width:42px;
        height: 42px;
        color: var(--primary);
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        line-height: 0;
        background-color: var(--tertiary);
        z-index: 2;
        font-size: 22px;
        border-radius: 50%;
        overflow: hidden;
    }
    .carIndicator.min .carQ{
        height: 30px;
        width: 30px;
        left: 30px;
        font-size: 14px;
    }
    .menuIcon{
        width: 50px;
        height: 50px;
        padding: 5px;
        cursor: pointer;
    }

    ::v-deep(.menuIcon svg){
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    ::v-deep(.menuIcon svg path){
        fill: var(--tertiary);
        stroke: var(--tertiary);
    }


    @media (min-width: 0px) and (max-width: 499px) { 

    }

    @media (min-width: 0px) and (max-width: 768px) { 
        #topMenu{
            margin: 1px 1px 0 1px;
            height: 80px;
        }
        .logo{
            height: 65% !important;
        }
        .menuIcon{
            width: 45px;
            height: 45px;
        }
    }

    @media (min-width: 769px){ 
        .h-md-75{
            height: 63% !important;
        }
    }

    

    .btn-1{
        overflow: none;
        font-size: 18px;
        color:var(--text8);
        width: auto;
        height: 100%;
        border: 0;
        background-color: transparent;
        line-height: 1;
    }

    .btn-1 .labeled{
        padding-top: 0.5em;
    }

    .btn-2 .labeled{
        padding-top: 0.3em;
    }

    .btn-2{
        overflow: none;
        color:var(--text4);
        font-size: 18px;
        width: 150px;
        height: 70%;
        border: 0;
        border-radius: 28px;
        background-color: var(--text2);
        padding-top: 0.1em;
        line-height: 1;
    }

    .contextMenuActivator:not(.btn-1) span.icon {
        display:none
    }

    .contextMenuActivator.btn-1 span.icon {
        display:flex !important;
        position:absolute;
        right:-35px;
        top:30%;
        width: 35px;
        padding-left: 10px;
        height: 35px;
        margin-right: 5px;
    }

    .contextMenuActivator span svg{
        width:100%;
        height:100%;
        object-fit:contain;
    }

    .btn-1:hover, .btn-2:hover{
        opacity: 0.8;
    }

    .carSpace{
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        color:var(--text2);
        cursor: pointer;
    }

    .carSpace:hover{
        filter: contrast(0.9);
    }

    .carSpace img, .carSpace svg{
        position: relative;
        height: 70px;
        z-index: 1;
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .carSpace.min img, .carSpace.min svg{
        height: 40px;
        object-position:100% 50%;
    }

    .carSpace.toggleIn img, .carSpace.toggleIn svg{
        animation: toggled 0.5s linear 0.4s 1;
    }

    .perfectSquare{
        width:100%;
        max-height: 100%;
        position: relative;
        border-radius: 17px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .perfectSquare:after {
        content: "";
        display: block;
        padding-bottom: 100%;
        padding-right: 100%;
    }
    .perfectSquare img{
        position: absolute;
        object-position: left center;
        object-fit: contain;
        left: auto;
        top:auto;
        width: auto;
        height: 100%;
        padding:2px;
        border-radius: 50%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .logo{
        background-color: #fff;
        border-radius: 50%;
        padding:4px;
    }
    .divisor{
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .divisor::after{
        content:"";
        display: flex;
        width: 1px;
        height: 40%;
        border-right:2px solid var(--vigequinary);
    }
    .divisor.mobile::after{
        height: 100%;
    }

    .floatingMenu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999; 
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: start;
        padding: 0; 
        background-color: rgba(0, 0, 0, 0.8); 
    }
      
    .menuContent {
        height: auto;
        overflow-y: auto;
        width: 80%;
        background-color: var(--primary); 
        border-radius: 0 40px 40px 0;
    }
    .menuContent.show{
        animation: slideApend 0.3s ease 1 forwards;
    }
    .menuContent.hidden{
        animation: slidePop 0.3s ease 1 forwards;
    }

    ::v-deep(.menuLine svg){
        width: 50px;
        height: 50px;
    }

    ::v-deep(.menuLine svg path){
        fill: var(--text2);
    }
    
    .menuLine{
        color: var(--text2);
        font-size: 40px;
        border-top: 3px solid var(--vigequinary);
    }
    .menuContent .menuLine:last-child{
        border-bottom: 3px solid var(--vigequinary);
    }
    .menuContent .contextMenuActivator img{
        width: 22px;
        height: auto;
    }

    .contextMenu{
        position:absolute;
        right:calc(100px + 1.9rem);
        top:100%;
        width:200px;
        height:auto;
        background-color:var(--quartenary);
        border-radius:10px;
    }

    .triangulePoint{
        position:absolute;
        bottom:100%;
        left:45%;
        width: 0;
        height: 0;
        border-left: 10px solid transparent; /* Mitad del ancho del triángulo */
        border-right: 10px solid transparent; /* Mitad del ancho del triángulo */
        border-bottom: 12px solid var(--quartenary); /* Altura del triángulo */
    }
    .menuContainer{
        overflow:hidden;
        border-radius:10px;
    }
    .menuBotton{
        cursor:pointer;
        color:var(--text2);
    }
    .menuBotton:not(:first-child){
        border-top:1px solid var(--decagenary);
    }
    .menuBotton:hover{
        background-color:var(--sexadecagenary);
    }

</style>